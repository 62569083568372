.relatorio{
    display: none;
}

.texto-center{
    text-align: center;
}

.texto-right{
    text-align: right;
}
 
@page {
    size: A4;
    margin: 11mm 17mm 17mm 17mm; 
}